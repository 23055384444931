var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" }
    },
    [
      _c(
        "v-flex",
        { staticClass: "ma-5 mb-0", attrs: { md12: "", xs12: "" } },
        [
          _c("v-card", { staticClass: "card pa-4" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "v-tabs",
                  {
                    staticClass: "tabs",
                    attrs: { "show-arrows": "" },
                    model: {
                      value: _vm.active,
                      callback: function($$v) {
                        _vm.active = $$v
                      },
                      expression: "active"
                    }
                  },
                  [
                    _c("v-tabs-slider"),
                    !_vm.canSeeUsers
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#gebruikers",
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Gebruikers")]
                        )
                      : _vm._e(),
                    !_vm.canSeeMollie
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#eigenaars",
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Mollie setup")]
                        )
                      : _vm._e(),
                    !_vm.canSeeTemplates
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#mailTemplates",
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Mail templates")]
                        )
                      : _vm._e(),
                    !_vm.canSeeRoles
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#permissions",
                              "active-class": "active"
                            }
                          },
                          [_vm._v("Rollen & Rechten")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "grid text-xs-center" }, [
        _c(
          "div",
          { attrs: { id: "profile-form" } },
          [
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              },
              [
                !_vm.canSeeUsers
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "gebruikers" } },
                      [_c("user-list")],
                      1
                    )
                  : _vm._e(),
                !_vm.canSeeMollie
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "eigenaars" } },
                      [_c("company-list")],
                      1
                    )
                  : _vm._e(),
                !_vm.canSeeTemplates
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "mailTemplates" } },
                      [_c("mail-templates-list")],
                      1
                    )
                  : _vm._e(),
                !_vm.canSeeRoles
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "permissions" } },
                      [_c("permissions-list")],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }