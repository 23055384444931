var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_c("h3", [_vm._v(_vm._s(_vm.modalTitle))])]),
      _c(
        "v-card-text",
        { staticClass: "pb-3" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { staticClass: "pa-5 pb-0", attrs: { md12: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex wrap" },
                  [
                    _vm._l(_vm.comments, function(comment, key) {
                      return _c(
                        "v-card",
                        {
                          key: key,
                          staticClass: "comments pa-4 mb-2",
                          attrs: { elevation: "2" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-caption mb-1 font-weight-light"
                                  },
                                  [_vm._v(_vm._s(comment.createdBy))]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-caption ml-3 mb-1 font-weight-light"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          comment.createdDateTime
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _vm.canEdit
                                ? _c(
                                    "div",
                                    [
                                      comment._id
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "mt-0",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteComment(
                                                    comment._id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("mdi-delete-forever")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c("div", [_vm._v(_vm._s(comment.text))])
                        ]
                      )
                    }),
                    _c("v-textarea", {
                      staticClass: "mt-3",
                      attrs: {
                        placeholder: "Schrijf hier uw opmerkingen",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.newComment,
                        callback: function($$v) {
                          _vm.newComment = $$v
                        },
                        expression: "newComment"
                      }
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2 pb-4" },
        [
          _c("v-spacer"),
          _vm.canEdit
            ? _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.addComment } },
                [_vm._v("Verzenden")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }