var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "secondary-text text-lg-left form-title"
                        },
                        [_vm._v("Blijvers")]
                      ),
                      _c("div", { staticClass: "flex pr-0" }, [
                        _c(
                          "div",
                          { staticClass: "mt-3 d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text",
                                attrs: { "x-large": "", color: "blue-grey" },
                                on: { click: _vm.download }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", left: "", dark: "" } },
                                  [_vm._v("mdi-file-excel")]
                                ),
                                _vm._v(" Download Blijvers ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "mb-5 pr-0 flex justify-end flex-column" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "d-flex justify-space-between px-0"
                            },
                            [
                              _c("filter-data-dialog-component", {
                                staticClass: "filter--container",
                                attrs: {
                                  acceptedFilters: _vm.acceptedFilters,
                                  uniqueFilterStoreName: "filter$stayingList",
                                  showFilters: true,
                                  showSearchBar: true
                                },
                                on: { filterData: _vm.setFilterOnList }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.canEdit
                        ? _c(
                            "div",
                            {
                              staticClass: "flex-row d-inline-flex align-center"
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "template-select mr-5",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  small: "",
                                  label: "Mail template kiezen",
                                  clearable: "",
                                  items: _vm.mailTemplates,
                                  "item-text": "name",
                                  "item-value": "_id"
                                },
                                on: { change: _vm.toggleDisableButton },
                                model: {
                                  value: _vm.selectedMailTemplate,
                                  callback: function($$v) {
                                    _vm.selectedMailTemplate = $$v
                                  },
                                  expression: "selectedMailTemplate"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    disabled: _vm.disableMail
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.mailSelected()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "primary" }
                                    },
                                    [_vm._v("mdi-email-outline")]
                                  ),
                                  _vm._v(" Onderstaande selectie mailen ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.getHeaders,
                            items: _vm.tenantsV2,
                            search: _vm.search,
                            options: _vm.options,
                            "server-items-length": _vm.totalTenants,
                            "footer-props": {
                              "items-per-page-options": [5, 10, 15]
                            },
                            loading: _vm.loading,
                            "item-key": "email"
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.options = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "body",
                              fn: function(ref) {
                                var items = ref.items
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(items, function(item) {
                                      return _c("tr", [
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  item.startDate
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  item.endDate
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.project["name"]))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.unit.type))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-xs-left text-center"
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "unitDetail",
                                                    params: {
                                                      id: item.unit._id
                                                    }
                                                  },
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.unit["name"]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.latestStayPrice) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.latestNewPrice) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [
                                            _vm._v(
                                              _vm._s(item.tenant["fullName"])
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            item.stayChoice
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.canEdit,
                                                      color: _vm.getStayLeaveChoiceColor(
                                                        item.stayChoice
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.resetStayLeaveChoice = true
                                                        _vm.selectedContract = item
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "mdi-" +
                                                            _vm.getStayLeaveChoiceIconText(
                                                              item.stayChoice
                                                            )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          _vm._l(
                                            _vm.tenantFilter.stayChoicesInfo,
                                            function(stayChoice) {
                                              return _c(
                                                "div",
                                                { key: stayChoice.name },
                                                [
                                                  stayChoice._id ===
                                                  item.stayChoiceInfo
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            stayChoice.name
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left px-3" },
                                          _vm._l(_vm.stayStatusItems, function(
                                            status
                                          ) {
                                            return _c(
                                              "div",
                                              { key: status["_id"] },
                                              [
                                                status["_id"] ===
                                                item.contractStatuses
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            disabled: !_vm.canEdit,
                                                            items:
                                                              _vm.stayStatusItems,
                                                            dense: "",
                                                            solo: "",
                                                            "hide-details": "",
                                                            "item-text": "name",
                                                            "item-value": "_id"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.StayStatus(
                                                                $event,
                                                                item._id,
                                                                item
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              item.contractStatuses,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "contractStatuses",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.contractStatuses"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            item.comments.length != 0
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "grey" },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.stayDialogItem = {
                                                          _id: item._id,
                                                          item: item
                                                        }
                                                        _vm.stayDialog = true
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" mdi-chat ")]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "grey" },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.stayDialogItem = {
                                                          _id: item._id,
                                                          item: item
                                                        }
                                                        _vm.stayDialog = true
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" mdi-chat-outline ")]
                                                )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "grey" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.goToStayingPage(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" mdi-link-variant ")]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn"
                                  }
                                },
                                [_vm._v(" Sorry, geen gebruikers gevonden ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.stayDialog,
            callback: function($$v) {
              _vm.stayDialog = $$v
            },
            expression: "stayDialog"
          }
        },
        [
          _vm.stayDialog
            ? _c("stay-dialog", {
                attrs: { item: _vm.stayDialogItem, canEdit: _vm.canEdit },
                on: { save: _vm.updateRemarks, delete: _vm.deleteRemark }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.canEdit && _vm.createContractDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", scrollable: "", persistent: "" },
              model: {
                value: _vm.createContractDialog,
                callback: function($$v) {
                  _vm.createContractDialog = $$v
                },
                expression: "createContractDialog"
              }
            },
            [
              _c("create-contract-dialog", {
                attrs: {
                  isLoading: _vm.isSavingContract,
                  extension: true,
                  item: _vm.createContractDialogItem,
                  canEdit: _vm.getRights.FOLLOW_UP_STAYERS
                },
                on: { save: _vm.extendContract, close: _vm.closeContractDetail }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.canEdit && _vm.endContractDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", scrollable: "", persistent: "" },
              model: {
                value: _vm.endContractDialog,
                callback: function($$v) {
                  _vm.endContractDialog = $$v
                },
                expression: "endContractDialog"
              }
            },
            [
              _c("end-contract-dialog", {
                attrs: { extension: true, item: _vm.endContractDialogItem },
                on: { save: _vm.editContract, close: _vm.closeContractDetail }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.resetStayLeaveChoice,
            callback: function($$v) {
              _vm.resetStayLeaveChoice = $$v
            },
            expression: "resetStayLeaveChoice"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Keuze resetten ")
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Wilt u de blijf / vertrek keuze verwijderen van de huurder?"
                  )
                ]),
                _c("p", [
                  _vm._v("De huurder kan dan opnieuw zijn of haar keuze maken")
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "mr-2" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.resetStayLeaveChoice = false
                          _vm.selectedContract = null
                        }
                      }
                    },
                    [_vm._v(" Nee ")]
                  ),
                  _vm.canEdit
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.doResetStayLeaveChoice()
                            }
                          }
                        },
                        [_vm._v("Ja")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }