var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c("div", { staticClass: "header-container" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header d-flex justify-space-between align-center"
                      },
                      [
                        _c("div", [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "secondary-text text-lg-left form-title"
                            },
                            [_vm._v("Units")]
                          )
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "mt-3 d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text",
                                  attrs: { "x-large": "", color: "blue-grey" },
                                  on: { click: _vm.download }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "", left: "", dark: "" }
                                    },
                                    [_vm._v("mdi-file-excel")]
                                  ),
                                  _vm._v(" Download Units ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              searchLabel:
                                "Zoeken naar Project / Kamer Type / Nummer / Stad",
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$rentList",
                              showFilters: true,
                              showSearchBar: true
                            },
                            on: { filterData: _vm.setFilterOnList }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            "item-key": "tableKey",
                            headers: _vm.headers,
                            menu: _vm.menu,
                            items: _vm.units,
                            options: _vm.options,
                            "server-items-length": _vm.totalCount,
                            loading: _vm.isLoading,
                            "footer-props": {
                              "items-per-page-options": [5, 10, 15]
                            }
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.options = $event
                            },
                            "update:page": _vm.setSkip,
                            "update:items-per-page": _vm.setLimit,
                            "update:sort-by": _vm.setSort,
                            "update:sort-desc": _vm.setSort
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item.betweenRange",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-xs-left",
                                      attrs: { title: item.availableFromTo }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              item.betweenRange
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.unitName",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    { staticClass: "text-xs-left" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "unitDetail",
                                              params: { id: item._id }
                                            },
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.unitName) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.status",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: !_vm.canEdit,
                                      items: _vm.unitStatuses,
                                      dense: "",
                                      solo: "",
                                      "hide-details": "",
                                      "item-text": "name",
                                      "item-value": "_id"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.rentStatus(
                                          $event,
                                          item._id,
                                          item.rentableUnitId,
                                          item
                                        ),
                                          (_vm.clickedStatus = true)
                                      }
                                    },
                                    model: {
                                      value: item.status,
                                      callback: function($$v) {
                                        _vm.$set(item, "status", $$v)
                                      },
                                      expression: "item.status"
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "item.unitRemarks",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "grey" },
                                      on: {
                                        click: function($event) {
                                          _vm.openCommentDialog(item),
                                            (_vm.clickedStatus = false)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.unitRemarks.length !== 0
                                              ? "mdi-chat"
                                              : "mdi-chat-outline"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.rentalCode",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _vm.editing === item
                                        ? _c("v-text-field", {
                                            staticStyle: {
                                              "font-size": "0.875rem",
                                              "min-width": "120px",
                                              padding: "5px 0"
                                            },
                                            attrs: {
                                              disabled: !_vm.canEdit,
                                              "full-width": "",
                                              autofocus: "",
                                              filled: "",
                                              dense: "",
                                              "hide-details": "",
                                              "prepend-inner-icon": "mdi-check"
                                            },
                                            on: {
                                              keyup: [
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "esc",
                                                      27,
                                                      $event.key,
                                                      ["Esc", "Escape"]
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.cancelEdit(item)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.editRentalCode(
                                                    item
                                                  )
                                                }
                                              ],
                                              "click:prepend-inner": function(
                                                $event
                                              ) {
                                                return _vm.editRentalCode(item)
                                              }
                                            },
                                            model: {
                                              value: _vm.editing.rentalCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editing,
                                                  "rentalCode",
                                                  $$v
                                                )
                                              },
                                              expression: "editing.rentalCode"
                                            }
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "min-width": "100px",
                                                display: "flex",
                                                "align-items": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.canEdit,
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.editRentalCode(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-pencil-circle-outline"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.rentalCode) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn"
                                  }
                                },
                                [_vm._v(" Sorry, geen units gevonden ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          on: { "click:outside": _vm.resetCommentsDialog },
          model: {
            value: _vm.currentComments.show,
            callback: function($$v) {
              _vm.$set(_vm.currentComments, "show", $$v)
            },
            expression: "currentComments.show"
          }
        },
        [
          _vm.currentComments.show
            ? _c("comments-dialog", {
                attrs: {
                  comments: _vm.currentComments.comments,
                  title: _vm.currentComments.title,
                  status: _vm.currentComments.status,
                  canEdit: _vm.canEdit
                },
                on: { add: _vm.addUnitComment, delete: _vm.removeUnitComment }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }