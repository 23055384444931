import { isEmpty, isNil } from 'ramda';

/**
 * The parseFilterData helper will parse the filterData to be used with the select component.
 * The select component expects an object with text/value properties.
 *
 * This helper will check if a property exists in the filterData object and if it does, add an entry in to the returned filters.
 * The objects in the filters object will be mapped to that text/value structure.
 *
 * @param filterData the filterData provided by the API.
 * @returns filters an object that consists of mapped data that can be used with the select component to filter the data-table.
 */
export default function (filterData) {
  if (isNil(filterData)) {
    return null;
  }

  const { projects, types, statuses, cities } = filterData;
  const filters = {};

  if (!isNil(projects) && !isEmpty(projects)) {
    filters.project = projects.map((project) => ({
      text: project.name,
      value: project._id,
    }));
  }

  if (!isNil(cities) && !isEmpty(cities)) {
    filters.city = cities.map((city) => ({
      text: city.name,
      value: city._id,
    }));
  }

  if (!isNil(types) && !isEmpty(types)) {
    filters.type = types.map((type) => ({
      text: type.name,
      value: type._id,
    }));
  }

  if (!isNil(statuses) && !isEmpty(statuses)) {
    filters.status = statuses.map((stat) => ({
      text: stat.name,
      value: stat._id,
    }));
  }

  return filters;
}
