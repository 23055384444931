var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_c("h3", [_vm._v("Beïndigen van contract")])]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "pa-3" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                            _c("h3", { staticClass: "black--text" }, [
                              _vm._v(
                                "Gelieve een datum te selecteren waneer de unit terug beschikbaar is"
                              )
                            ]),
                            _c("h4", [
                              _vm._v(
                                _vm._s(_vm.item.project.name) +
                                  " - " +
                                  _vm._s(_vm.item.unit.name) +
                                  " (" +
                                  _vm._s(_vm.item.unit.type) +
                                  ")"
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "max-width": "290"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Beschikbaar vanaf",
                                                    "persistent-hint": "",
                                                    clearable: "",
                                                    readonly: ""
                                                  },
                                                  on: {
                                                    "click:clear": function(
                                                      $event
                                                    ) {
                                                      _vm.endDate = null
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.endDateFormatted,
                                                    callback: function($$v) {
                                                      _vm.endDateFormatted = $$v
                                                    },
                                                    expression:
                                                      "endDateFormatted"
                                                  }
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.menu1,
                                    callback: function($$v) {
                                      _vm.menu1 = $$v
                                    },
                                    expression: "menu1"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    on: {
                                      input: function($event) {
                                        _vm.menu1 = false
                                      }
                                    },
                                    model: {
                                      value: _vm.endDate,
                                      callback: function($$v) {
                                        _vm.endDate = $$v
                                      },
                                      expression: "endDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Verhuur code",
                                  "persistent-hint": "",
                                  clearable: ""
                                },
                                on: {
                                  "click:clear": function($event) {
                                    _vm.unitRentalCode = ""
                                  }
                                },
                                model: {
                                  value: _vm.unitRentalCode,
                                  callback: function($$v) {
                                    _vm.unitRentalCode = $$v
                                  },
                                  expression: "unitRentalCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end mr-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("Annuleren")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.saveClicked }
                                },
                                [_vm._v("Contract Beëindigen")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }